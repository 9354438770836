import React from "react"
import Img from 'gatsby-image';

import Layout from "../components/layout/ca"
import SEO from "../components/seo"

const Homepage = (props) => {

 //  const { data: {allPrismicHomepage: nodes } } = props
	// const { data } = nodes[0]
  // <h1>{ data.main_title.text }</h1>
	return (
		<Layout location={props.location}>
			<SEO title="Home" />
			<h1>REVIEW BECAUSE WAS FAILING</h1>
		</Layout>
	)
}


export default Homepage


export const homepageQuery = graphql`
  query($id: String!) {
    allPrismicHomepage(filter: {id: {eq: $id }}) {
      nodes {
        data {
          main_background_image {
            url
          }
          main_title {
            text
          }
        }
      }
    }
  }
`